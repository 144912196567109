<template>
  <v-card>
    <v-card-title>
      <v-btn
        id="btn1"
        color="primary"
        dark
        @click="dialogModal(true, 'create')"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="users"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-slot:item.expire="{ item }">
        <v-chip v-if="diffDays(new Date(item.expire), new Date()) > 0" small color="success">
          {{ diffDays(new Date(item.expire)) }} يوم
        </v-chip>
        <v-chip v-else small color="error">
          منتهي الصلاحية
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div id="actions" class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item)"></menu-item>
              <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <user-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></user-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import UserDialog from '@/views/pages/managers/dialog.vue'
import MenuItem from '@/components/menu/menuItem.vue';

export default {
  name: 'Managers',
  components: { UserDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.managers',
    filters: false,
    users: [],
    usersTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'الاسم',
        align: 'center',
        sortable: true,
        value: 'name',
      },
      {
        text: 'رقم الهاتف',
        value: 'phone',
        align: 'center',
        sortable: true,
      },
      {
        text: 'البريد',
        value: 'email',
        align: 'center',
        sortable: true,
      },
      {
        text: 'الوصف',
        align: 'center',
        sortable: true,
        value: 'description',
      },
      {
        text: 'الخيارات',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.loading = true;
      const response = await axios.get('/user/getManagers');
      this.users = response.data.data
      this.usersTotal = response.data.data.length
      this.loading = false
    },
    dialogModal(dialog, type = this.dialogData.type, data = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.data = data;
    },
    diffDays(end, start = new Date()) {
      const diffInMs = end - start;
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      return Math.round(diffInDays);
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
